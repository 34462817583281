<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="delivery.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="delivery_title">Delivery Title:</label>
        <input class="form-control" name="delivery_title" type="text" required v-model="delivery.delivery_title">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="delivery_date">Delivery Date:</label>
        <input class="form-control" name="date" type="date" required v-model="delivery.delivery_date">
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="delivery.vehicle_id" :options="vehicleOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="delivery.driver_id" :options="driverOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="delivery_note">Delivery Note:</label>
        <textarea class="form-control" name="delivery_note" v-model="delivery.delivery_note"></textarea>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;border-bottom: 1px solid black;padding-bottom: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Location</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="address">Address:</label>
            <GMapAutocomplete 
              ref="address" 
              type="text" 
              :selectFirstOnEnter=true 
              :options="{
                componentRestrictions: {country:'id'}
              }" 
              :class="'form-control ' + (has_error ? 'is-invalid' : '')" 
              placeholder="Start typing" 
              @place_changed="getAddressData">
            </GMapAutocomplete>
          </div>
          <div class="form-group col-sm-12 col-md-4 required">
            <label for="status">Type:</label>
            <select class="form-control" name="type" v-model.number="type">
              <option value="1" style="color:black;">Pickup</option>
              <option value="2" style="color:black;">Delivery</option>
            </select>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="customer">Customer:</label>
            <input class="form-control" name="customer" type="text" v-model="customer">
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="invoice_code">Invoice Number:</label>
            <input class="form-control" name="invoice_code" type="text" v-model="invoice_code">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="description">Description:</label>
            <textarea class="form-control" name="description" v-model="description"></textarea>
          </div>
          <InputImage label="Picture" v-on:selected-image="setImage" ref="imageComponent"/>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Address</th>
                  <th>Customer</th>
                  <th>Invoice Number</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in delivery.delivery_details" :key="index">
                <td>{{item.type == 1 ? 'Pickup' : 'Delivery'}}</td>
                <td>{{item.address}}</td>
                <td>{{item.customer}}</td>
                <td>{{item.invoice_code}}</td>
                <td>{{item.description}}</td>
                <td>                  
                  <span v-if="index > 0" class="btn btn-success mr-1" @click.prevent="moveUp(index)"><i class="fa fa-caret-square-up"></i></span>
                  <span v-if="index < delivery.delivery_details.length - 1" class="btn btn-success mr-1" @click.prevent="moveDown(index)"><i class="fa fa-caret-square-down"></i></span>
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/deliveries">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  import InputImage from '../components/InputImage.vue'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('drivers', ['drivers']),
      ...mapState('deliveries', ['onRequest']),
      google: VueGoogleMaps,
    },
    components:{
      Select2,
      InputImage,
    },
    data(){
      return {
        delivery: {
          account_id: '',
          vehicle_id: '',
          driver_id: '',
          delivery_note: '',
          delivery_title: '',
          delivery_date: moment().format("YYYY-MM-DD"),
          delivery_details: [],
        },
        isEdit: false,
        has_error: false,
        editIndex: null,
        address:null, 
        lat:null, 
        lng:null, 
        type:1, 
        customer:null,
        invoice_code:null, 
        description:null, 
        file_image: null,
        accountOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        if(this.delivery.delivery_details.length < 1){
          this.error('Please Add Location First.')
          return
        }
        this.add(this.delivery)
      },
      ...mapActions('deliveries', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.delivery.vehicle_id = ''
        this.delivery.driver_id = ''
      },
      setImage(image){
        this.file_image = image
      },
      clickAdd(){
        if(this.address === null || this.address === '' || this.lat === null || this.lng === null){
          return;
        }
        let obj = {
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          description: this.description,
          invoice_code: this.invoice_code,
          customer: this.customer,
          type: this.type,
          file_image: this.file_image,
        }
        if(this.isEdit){
          this.delivery.delivery_details[this.editIndex] = obj
        } else {
          this.delivery.delivery_details.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {address, lat, lng, description, invoice_code, customer, type} = this.delivery.delivery_details[index]
        this.address = address
        this.lat = lat
        this.lng = lng
        this.type = type
        this.description = description
        this.invoice_code = invoice_code
        this.customer = customer
        this.file_image = null
        this.$refs.address.$el.value = address
        this.$refs.imageComponent.clearImage();
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.delivery.delivery_details.splice(index, 1)
        this.resetData()
      },
      getAddressData(addressData){
        const {geometry} = addressData
        if(!geometry){
          this.has_error = true   
          this.lat = null
          this.lng = null
          return
        }
        this.address = this.$refs.address.$el.value
        this.lat = geometry.location.lat()
        this.lng = geometry.location.lng()
        this.has_error = false  
      },
      moveUp(index){
        var a = this.delivery.delivery_details[index]
        this.delivery.delivery_details[index] = this.delivery.delivery_details[index - 1]
        this.delivery.delivery_details[index - 1] = a
        this.resetData()
      },
      moveDown(index){
        let a = this.delivery.delivery_details[index]
        this.delivery.delivery_details[index] = this.delivery.delivery_details[index + 1]
        this.delivery.delivery_details[index + 1] = a
        this.resetData()
      },
      resetData(){
        this.address = null
        this.lat = null
        this.lng = null
        this.description = null
        this.invoice_code = null
        this.customer = null
        this.type = 2
        this.file_image = null
        this.$refs.address.$el.value = null
        this.$refs.imageComponent.clearImage();
        this.has_error = false
        this.isEdit = false
        this.editIndex = null
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Delivery')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>
