<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" required v-model.number="cost.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Cost Date:</label>
        <input class="form-control" required name="cost_date" type="date" v-model="cost.cost_date">
      </div>
      <div class="form-group col-sm-6">
        <label for="name">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="cost.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-6">
        <label for="name">Driver:</label>
        <Select2 name="driver_id" v-model.number="cost.driver_id" :options="driverOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="note">Note:</label>
        <textarea class="form-control" name="note" v-model="cost.note"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="cost.status" disabled>
          <option value="0" style="color:black;">Need Approval</option>
          <option value="1" style="color:black;">Approved</option>
          <option value="2" style="color:black;">Paid</option>
        </select>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail Cost</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_name">Cost Name:</label>
            <input class="form-control" name="cost_name" type="text" v-model="cost_name">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_amount">Cost Amount:</label>
            <input class="form-control" name="cost_amount" type="text" v-model="cost_amount_txt">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="note">Note:</label>
            <textarea class="form-control" name="note" v-model="note"></textarea>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Note</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in cost.cost_details" :key="index">
                <td>{{item.cost_name}}</td>
                <td>{{formatNumber(item.cost)}}</td>
                <td>{{item.note}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/costs">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
        ...mapState('costs', ['onRequest']),
        cost_amount_txt:{
          get() {
            return this.cost_amount ? this.cost_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.modelValue
          },
          set(value) {
            this.cost_amount = Number(value.replaceAll(',',''))
          }
        }
    },
    data(){
      return {
        cost: {
          account_id: '',
          cost_date: moment().format("YYYY-MM-DD"),
          vehicle_id: '',
          driver_id: '',
          note: '',
          status: 0,
          cost_details: [],
        },
        isEdit: false,
        editIndex: null,
        cost_name: '',
        cost_amount: null,
        note: '',
        accountOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    components: {
      Select2,
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        if(this.cost.cost_details.length < 1){
          this.error('Please Add Detail Cost First.')
          return
        }
        this.add(this.cost)
      },
      onChangeAccount(event){
        this.get_vehicle_by_account({account_id: parseInt(event.id)})
        this.get_driver_by_account({account_id: parseInt(event.id)})
        this.cost.vehicle_id = ''
        this.cost.driver_id = ''
      },
      clickAdd(){
        if(this.cost_name === '' || this.cost_amount == null || this.cost_amount < 0){
          return;
        }
        if(isNaN(this.cost_amount)){
          return
        }
        let obj = {
          cost_name: this.cost_name,
          cost: this.cost_amount,
          note: this.note,
        }
        if(this.isEdit){
          this.cost.cost_details[this.editIndex] = obj
        } else {
          this.cost.cost_details.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {cost, cost_name, note} = this.cost.cost_details[index]
        this.cost_name = cost_name
        this.cost_amount = cost
        this.note = note
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.cost.cost_details.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.cost_name= ''
        this.cost_amount= null
        this.note= ''
      },
      ...mapActions('costs', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('drivers', ['get_driver_by_account']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Cost')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>
