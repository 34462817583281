<template>
  <div v-if="dashboard" class="row row-eq-height dark-mode">
    <div v-if="dashboard.status_vehicle" class="col-lg-6 mt-3">
      <div class="card" style="height:100%">
        <div class="card-body">
          <DoughnutChart :label="'Status Vehicle'" :data="dashboard.status_vehicle"/>
        </div>
      </div>
    </div>
    <div v-if="dashboard.top_checkpoint" class="col-lg-6 mt-3">
      <div class="card" style="height:100%">
        <div class="card-body">
          <PieChart :label="'Top 10 Checkpoint'" :data="dashboard.top_checkpoint"/>
        </div>
      </div>
    </div>
    <div v-if="dashboard.recent_events" class="col-lg-12 mt-3">
      <div class="card" style="height:100%">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h4>Recent Events</h4>
            </div>
            <div class="col-lg-12 pt-3 table-responsive">
              <Datatable v-bind:entries="dashboard.recent_events" :columns="recent_events" :currEntries="5" :title="'Recent Events'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import DoughnutChart from '../components/DoughnutChart.vue'
  import PieChart from '../components/PieChart.vue'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
        ...mapState('dashboards', ['dashboard']),
    },
    components:{
      DoughnutChart,
      PieChart,
      Datatable,
    },
    data(){
      return {
        recent_events: [
          {name: 'created_at', text: 'Time', raw: {
            type: 'Moment_UTC',
          }, order: 'desc'},
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {name: 'message', text: 'Event'},
          {text: 'Location', raw:{
            type: 'gps_lat_lng'
          }},
        ]
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {route} = this
        this.add(route)
      },
      ...mapActions('dashboards', ['get_data']),
    },
    created() {
      this.get_data()
    },
  }
</script>
