<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="deliveries" :columns="columns" :title="'Trips'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('deliveries', ['deliveries', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        let data = this.first_table
        data.push({name: 'action', text: 'Action',
          raw: {
            type: 'Action',
            fields: [
              {event: 'edit_delivery', text: 'View', method: 'get', class: 'btn btn-primary'},
              getCapability('delete_delivery', this.user) ? {event: 'deliveries/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              {event: 'print_delivery', text: 'Print', method: 'print-delivery', class: 'btn btn-info ml-1'},
            ]
          }
        })
        return data
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        from_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        to_date: moment().add(1, 'days').format("YYYY-MM-DD"),
        first_table :[
          {name: 'delivery_date', text: 'Date', order: 'desc', raw:{type:'Moment_date'}},
          {name: 'delivery_title', text: 'Delivery Title'},
          {name: 'delivery_code', text: 'Delivery Code'},
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {name: 'account', text: 'Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '0', text: 'Draft'},
                {value: '1', text: 'Started'},
                {value: '2', text: 'Completed'},
                {value: '3', text: 'Cancelled'},
              ]
            }
          },
          {name: 'start', text: 'Start',
            raw: {
              type: 'Moment_UTC'
            }
          },
          {name: 'finish', text: 'Finish',
            raw: {
              type: 'Moment_UTC'
            }
          },
          {name: 'created_at', text: 'Created', raw:{type:'Moment_UTC'}},
        ],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('deliveries', ['get_delivery_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(from_date).diff(moment(to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.get_delivery_by_account({account_id, from_date, to_date})
      },
    },
    created() {
      this.$emit('onChildInit', 'Deliveries', getCapability('add_delivery', this.user) ? '/deliveries/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
