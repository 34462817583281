<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="delivery.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="delivery.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="delivery_title">Delivery Title:</label>
        <input class="form-control" name="delivery_title" type="text" required v-model="delivery.delivery_title">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="delivery_date">Delivery Date:</label>
        <input class="form-control" name="date" type="date" required v-model="delivery_date">
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="delivery.vehicle_id" :options="vehicleOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="delivery.driver_id" :options="driverOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="delivery_note">Delivery Note:</label>
        <textarea class="form-control" name="delivery_note" v-model="delivery.delivery_note"></textarea>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;border-bottom: 1px solid black;padding-bottom: 20px;margin-bottom: 20px;">
        <div class="row" v-if="isAbleToEdit">
          <div class="form-group col-sm-12">
            <h4>Add Location</h4>
          </div>
        </div>
        <div class="row" v-if="isAbleToEdit">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="address">Address:</label>
            <GMapAutocomplete 
              ref="address" 
              type="text" 
              :selectFirstOnEnter=true 
              :options="{
                componentRestrictions: {country:'id'}
              }" 
              :class="'form-control ' + (has_error ? 'is-invalid' : '')" 
              placeholder="Start typing" 
              @place_changed="getAddressData">
            </GMapAutocomplete>
          </div>
          <div class="form-group col-sm-12 col-md-4 required">
            <label for="status">Type:</label>
            <select class="form-control" name="type" v-model.number="type">
              <option value="1" style="color:black;">Pickup</option>
              <option value="2" style="color:black;">Delivery</option>
            </select>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="customer">Customer:</label>
            <input class="form-control" name="customer" type="text" v-model="customer">
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="invoice_code">Invoice Number:</label>
            <input class="form-control" name="invoice_code" type="text" v-model="invoice_code">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="description">Description:</label>
            <textarea class="form-control" name="description" v-model="description"></textarea>
          </div>
          <InputImage label="Picture" v-on:selected-image="setImage" ref="imageComponent"/>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Address</th>
                  <th>Customer</th>
                  <th>Invoice Number</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Arrived</th>
                  <th v-if="isAbleToEdit">Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in delivery.delivery_details" :key="index">
                <td>{{item.type == 1 ? 'Pickup' : 'Delivery'}}</td>
                <td>{{item.address}}</td>
                <td>{{item.customer}}</td>
                <td>{{item.invoice_code}}</td>
                <td>{{item.description}}</td>
                <td>{{item.status == 0 ? 'Not Completed' : 'Completed'}}</td>
                <td>{{item.arrived_time ? getTime(item.arrived_time) : ''}}</td>
                <td v-if="isAbleToEdit">
                  <span v-if="index > 0" class="btn btn-success mr-1" @click.prevent="moveUp(index)"><i class="fa fa-caret-square-up"></i></span>
                  <span v-if="index < delivery.delivery_details.length - 1" class="btn btn-success mr-1" @click.prevent="moveDown(index)"><i class="fa fa-caret-square-down"></i></span>
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input v-if="isAbleToEdit" class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/deliveries">Back</router-link>
        <button v-if="delivery && delivery.status > 0 && delivery.status < 3" class="btn btn-info ml-1" @click="trackLocation($event)">Track Location</button>
      </div>
      <div class="col-sm-12 col-md-12">
        <GMapMap
            :center="center"
            :zoom="14"
            :disableDefaultUI="true"
            :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: true,
                  fullscreenControl: true,
            }"
            ref="gmap"
            map-type-id="terrain"
            style="width: 100%; height: 50vh"
        >
          <GMapMarker
            :key="index"
            v-for="(m, index) in delivery.delivery_details"
            :position="m"
          >
            <GMapInfoWindow
              :options="{
                maxWidth: 300,
              }"
              :position="m"
              :opened="true">
              <div class="text-center" v-html="`<b>${m.address}</b><br/>`+getTime(m.arrived_time)"></div>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapPolyline
            v-if="location_history && location_history.length > 0"
            :path="location_history"
            :options="{ strokeColor:'#0000FF', strokeWeight: 2}"
            />
          <GMapMarker
            v-if="selectedData"
            :position="this.selectedData"
            ref="selectedMarker"
            :icon="this.icon"
            :clickable="false"
            :draggable="false"
          />
        </GMapMap>
      </div>
      <div class="col-sm-12 col-md-12" v-if="location_history.length > 0">
        <div class="row">
          <div class="form-group col-sm-12">
            <button class="btn btn-success mt-3" @click="play($event)"><i class="fas fa-play"></i></button>
            <button class="btn btn-success mt-3 mx-3" @click="pause($event)"><i class="fas fa-pause"></i></button>
            <button class="btn btn-success mt-3" @click="stop($event)"><i class="fas fa-stop"></i></button>
            <select class="mx-3" name="speed_play" v-model.number="speed_play">
              <option value="1">x1</option>
              <option value="2">x2</option>
              <option value="3">X3</option>
              <option value="4">x4</option>
              <option value="5">x5</option>
              <option value="6">x6</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  import InputImage from '../components/InputImage.vue'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  export default {
    components:{
      InputImage,
      Select2,
    },
    computed: {
      ...mapState('deliveries', ['delivery', 'onRequest']),
      ...mapState('location_histories', ['location_history']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('drivers', ['drivers']),
      ...mapState('users', ['user']),
      google: VueGoogleMaps,
      icon(){
        return {
          path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
          size: {width: 60, height: 90, f: 'px', b: 'px',},
          scale: 0.7,
          rotation: (this.selectedData ? parseInt(this.selectedData.course) : 0),
          fillColor: (this.selectedData ? this.getColor(this.selectedData.status) : ""),
          fillOpacity: 1,
          anchor: new window.google.maps.Point(15, 25),
        }
      },
      delivery_date:{
        get(){
          if(this.delivery && this.delivery.delivery_date){
            return moment(this.delivery.delivery_date).format("YYYY-MM-DD")
          }
          return null
        },
        set(val){
          this.delivery.delivery_date = val
        }
      },
      isAbleToEdit(){
        return getCapability('edit_delivery', this.user)
      },
    },
    data(){
      return {
        has_error: false,
        address:null, 
        lat:null, 
        lng:null, 
        type:2, 
        customer:null,
        invoice_code:null, 
        description:null, 
        file_image: null,
        arrived_time: null,
        status: 0,
        vehicleOptions: [],
        driverOptions: [],
        isEdit: false,
        editIndex: null,        
        center: {lat: -6.21462, lng: 106.84513},
        isFitOnce: false,
        selectedData: null,
        histories_moving: [],
        speed_play: 1,
        played_idx: 0,
        timer: null,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        clearInterval(this.timer)
        if(this.delivery.delivery_details.length < 1){
          this.error('Please Add Location First.')
          return
        }
        this.delivery.delivery_date = this.delivery_date
        this.edit(this.delivery)
      },
      ...mapActions('deliveries', ['edit', 'get']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('location_histories', ['get_data', 'clear_data']),
      setImage(image){
        this.file_image = image
      },
      getTime(time){
        if(time){
          return moment(time).format("lll")
        }
        return "";
      },
      clickAdd(){
        if(this.address === null || this.address === '' || this.lat === null || this.lng === null){
          return;
        }
        let obj = {
          id: this.id,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          description: this.description,
          invoice_code: this.invoice_code,
          customer: this.customer,
          type: this.type,
          status: this.status,
          arrived_time: this.arrived_time,
          file_image: this.file_image,
        }
        if(this.isEdit){
          this.delivery.delivery_details[this.editIndex] = obj
        } else {
          this.delivery.delivery_details.push(obj)
        }
        this.resetData()
      },
      clickEdit(event, index){
        event.preventDefault()
        const {id, address, lat, lng, description, invoice_code, customer, type, status, arrived_time} = this.delivery.delivery_details[index]
        this.id = id
        this.address = address
        this.lat = lat
        this.lng = lng
        this.description = description
        this.invoice_code = invoice_code
        this.customer = customer
        this.type = type
        this.status = status
        this.file_image = null
        this.arrived_time = arrived_time
        this.$refs.address.$el.value = address
        this.$refs.imageComponent.clearImage();
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.delivery.delivery_details.splice(index, 1)
        this.resetData()
      },  
      getAddressData(addressData){
        const {geometry} = addressData
        if(!geometry){
          this.has_error = true   
          this.lat = null
          this.lng = null
          return
        }
        this.address = this.$refs.address.$el.value
        this.lat = geometry.location.lat()
        this.lng = geometry.location.lng()
        this.has_error = false  
      },    
      moveUp(index){
        var a = this.delivery.delivery_details[index]
        this.delivery.delivery_details[index] = this.delivery.delivery_details[index - 1]
        this.delivery.delivery_details[index - 1] = a
        this.resetData()
      },
      moveDown(index){
        let a = this.delivery.delivery_details[index]
        this.delivery.delivery_details[index] = this.delivery.delivery_details[index + 1]
        this.delivery.delivery_details[index + 1] = a
        this.resetData()
      },
      resetData(){
        this.id = null
        this.address = null
        this.lat = null
        this.lng = null
        this.description = null
        this.invoice_code = null
        this.customer = null
        this.type = 2
        this.status = 0
        this.file_image = null
        this.arrived_time = null
        this.$refs.address.$el.value = null
        this.$refs.imageComponent.clearImage();
        this.has_error = false
        this.isEdit = false
        this.editIndex = null
      },
      trackLocation(event){
        event.preventDefault()
          const {vehicle_id, start, finish} = this.delivery
        if(vehicle_id == null || start == null){
          return;
        }
        let from_date = moment(start).utc().format('YYYY-MM-DD')
        let from_time = moment(start).utc().format('HH:mm')
        let to_date = moment().utc().format('YYYY-MM-DD')
        let to_time = moment().utc().format('HH:mm')
        if(finish != null){
          to_date = moment(finish).utc().format('YYYY-MM-DD')
          to_time = moment(finish).utc().format('HH:mm')
        }
        this.get_data({vehicle_id, from_date, from_time, to_date, to_time})
      },
      onRowClicked(item){
        this.selectedData = item
        if(this.$refs.selectedMarker && this.selectedData){
          this.$refs.selectedMarker.$markerObject.setPosition(item);
          this.$refs.gmap.$mapPromise.then(() => {
            this.$refs.gmap.panTo(this.$refs.selectedMarker.$markerObject.getPosition())
          })
        }
      },
      getColor(data){
        if(data.speed > 0){
          return '#3bb143'
        }
        return '#ef5350'
      },
      play(event){
        event.preventDefault()
        clearInterval(this.timer)
        const self = this;
        this.timer = setInterval(function(){
          let item = self.histories_moving[self.played_idx]
          self.onRowClicked(item)
          if(self.played_idx < self.histories_moving.length){
            self.played_idx++
          }
        }, 1000 / self.speed_play)
      },
      pause(event){
        event.preventDefault()
        clearInterval(this.timer)
      },
      stop(event){
        event.preventDefault()
        this.played_idx = 0;
        this.selectedData = null
        clearInterval(this.timer)
      },
    },
    created() {
      this.$emit('onChildInit', 'Detail Delivery')
      this.get(this.$route.params.id)
    },
    unmounted(){
      clearInterval(this.timer)
    },
    watch:{
      delivery(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.account_id){
            this.getVehicles({account_id : nextState.account_id})
          }
          if(nextState.delivery_details){
            nextState.delivery_details.sort((a, b) => {
              if(a.sequence >= b.sequence){
                return 1
              } else {
                return -1
              }
            })
          }
          if(nextState.account_id){
            this.getDrivers({account_id : nextState.account_id})
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },      
      location_history (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.length > 0){
            nextState.sort(function(a, b){
              if(a.device_time < b.device_time) return -1
              else return 1
            })
            if(!this.isFitOnce){
              this.$refs.gmap.$mapPromise.then(() => {
                let bounds = new window.google.maps.LatLngBounds()
                nextState.forEach(loc => {
                  bounds.extend(loc)
                })
                this.$nextTick().then(() => {
                  this.$refs.gmap.fitBounds(bounds)
                })
                this.$refs.gmap.panToBounds(bounds, 100)
              })
              this.isFitOnce = true
            }
            this.histories_moving = nextState.filter(item => item.speed > 0)
          }
          return
        }
      }
    }
  }
</script>
