<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="account_id">Account:</label>
        <input v-if="cost.account" class="form-control" disabled name="account_id" type="text" v-model="cost.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Cost Date:</label>
        <input class="form-control" required name="cost_date" type="date" v-model="cost.cost_date" :disabled="cost.status > 0">
      </div>
      <div class="form-group col-sm-6">
        <label for="name">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="cost.vehicle_id" :options="vehicleOptions" :disabled="cost.status > 0"/>
      </div>
      <div class="form-group col-sm-6">
        <label for="name">Driver:</label>
        <Select2 name="driver_id" v-model.number="cost.driver_id" :options="driverOptions" :disabled="cost.status > 0"/>
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="note">Note:</label>
        <textarea class="form-control" name="note" v-model="cost.note" :disabled="cost.status > 0"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="cost.status" disabled>
          <option value="0" style="color:black;">Need Approval</option>
          <option value="1" style="color:black;">Approved</option>
          <option value="2" style="color:black;">Paid</option>
        </select>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div v-if="cost.status == 0" class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail Cost</h4>
          </div>
        </div>
        <div v-else class="row">
          <div class="form-group col-sm-12">
            <h4>Detail Cost</h4>
          </div>
        </div>
        <div v-if="cost.status == 0" class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_name">Cost Name:</label>
            <input class="form-control" name="cost_name" type="text" v-model="cost_name">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_amount">Cost Amount:</label>
            <input class="form-control" name="cost_amount" type="text" v-model="cost_amount_txt">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="note">Note:</label>
            <textarea class="form-control" name="note" v-model="note"></textarea>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Note</th>
                  <th v-if="cost.status == 0">Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in cost.cost_details" :key="index">
                <td>{{item.cost_name}}</td>
                <td>{{formatNumber(item.cost)}}</td>
                <td>{{item.note}}</td>
                <td v-if="cost.status == 0">                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <button v-if="isAbleToApprove && this.cost && this.cost.status == 0" class="btn btn-success mr-1" @click.prevent="approve($event)">Approve</button>
        <button v-if="isAbleToSetPaid && this.cost && this.cost.status == 1" class="btn btn-success mr-1" @click.prevent="setPaid($event)">Set Paid</button>
        <input v-if="isAbleToEdit && this.cost && this.cost.status == 0" class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/costs">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
      isAbleToApprove(){
        return getCapability('approve_cost', this.user)
      },
      isAbleToEdit(){
        return getCapability('edit_cost', this.user)
      },
      isAbleToSetPaid(){
        return getCapability('set_paid_cost', this.user)
      },
        ...mapState('costs', ['cost', 'onRequest']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
      ...mapState('users', ['user']),
      cost_amount_txt:{
        get() {
          return this.cost_amount ? this.cost_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.modelValue
        },
        set(value) {
          this.cost_amount = Number(value.replaceAll(',',''))
        }
      }
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        if(this.cost.cost_details.length < 1){
          this.error('Please Add Detail Cost First.')
          return
        }
        this.edit(this.cost)
      },
      clickAdd(){
        if(this.cost_name === '' || this.cost_amount == null || this.cost_amount < 0){
          return;
        }
        if(isNaN(this.cost_amount)){
          return
        }
        let obj = {
          cost_name: this.cost_name,
          cost: this.cost_amount,
          note: this.note,
        }
        if(this.isEdit){
          this.cost.cost_details[this.editIndex] = obj
        } else {
          this.cost.cost_details.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {cost, cost_name, note} = this.cost.cost_details[index]
        this.cost_name = cost_name
        this.cost_amount = cost
        this.note = note
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.cost.cost_details.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.cost_name= ''
        this.cost_amount= null
        this.note= ''
      },
      approve(e){
        e.preventDefault()
        if(this.onRequest) return;
        this.clear()
        if(this.cost.cost_details.length < 1){
          this.error('Please Add Detail Cost First.')
          return
        }
        this.cost.status = 1
        this.edit(this.cost)
      },
      setPaid(e){
        e.preventDefault()
        if(this.onRequest) return;
        this.clear()
        if(this.cost.cost_details.length < 1){
          this.error('Please Add Detail Cost First.')
          return
        }
        this.cost.status = 2
        this.edit(this.cost)
      },
      ...mapActions('costs', ['edit', 'get']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('drivers', ['get_driver_by_account']),
    },
    components: {
      Select2,
    },
    data(){
      return {
        isEdit: false,
        editIndex: null,
        cost_name: '',
        cost_amount: null,
        note: '',
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    created() {
      this.$emit('onChildInit', 'Edit Cost')
      this.get(this.$route.params.id)
    },
    watch: {
      cost (nextState, prevState) {
        if(nextState !== prevState){
          this.get_vehicle_by_account({account_id: nextState.account_id})
          this.get_driver_by_account({account_id: nextState.account_id})
          if(nextState.cost_date){
            nextState.cost_date = moment(nextState.cost_date).format("YYYY-MM-DD")
          }
          return
        }
      },      
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>
